<template>
    <div class="relative w-full text-left">
        <label
            v-if="label"
            class="text-sm transition-all duration-300"
            :class="{
                'text-primary': focused,
                'text-neutral-darkest': !focused,
                'text-error': $store.getters.hasError(name, scope),
            }"
        >
            {{ label }}
        </label>
        <div class="relative flex cursor-pointer flex-col justify-center">
            <div
                tabindex="0"
                class="w-full appearance-none truncate rounded border border-neutral-lighter py-2 pl-4 pr-10 text-sm text-neutral-darkest outline-none transition-all duration-300 md:rounded-lg md:border-2"
                :class="{
                    'border-primary': focused,
                    'border-error': $store.getters.hasError(name, scope),
                    'text-opacity-25': !selectedLabel,
                }"
                @focus="focused = true"
                @blur="focused = false"
            >
                {{ selectedLabel || placeholder }}
            </div>
            <Icon
                class="pointer-events-none absolute right-0 mr-4 transition-all duration-300"
                :class="{
                    'text-primary': focused,
                    'text-neutral-darkest': !focused,
                    'text-error': $store.getters.hasError(name, scope),
                }"
                :icon="['far', 'chevron-down']"
            />
            <transition enter-class="opacity-0" leave-to-class="opacity-0">
                <div
                    v-if="$store.getters.hasError(name, scope)"
                    class="absolute bottom-0 left-0 max-w-full translate-y-full transform truncate text-xs text-error transition-all duration-300"
                >
                    {{ $t($store.getters.getError(name, scope)) }}
                </div>
            </transition>
            <transition enter-class="opacity-0" leave-to-class="opacity-0">
                <div
                    v-if="focused"
                    class="absolute right-0 top-14 z-10 flex w-40 flex-col gap-1 overflow-auto rounded-lg border border-neutral-lighter bg-white p-1 text-sm transition-all duration-300 md:border-2"
                    style="max-height: 20rem"
                >
                    <div
                        v-for="(option, index) in options"
                        :key="index"
                        class="truncate rounded px-4 py-2 transition-all duration-300"
                        :class="[
                            value === option.value
                                ? 'border border-neutral-lighter bg-neutral-lightest'
                                : 'hover:bg-neutral-lightest',
                        ]"
                        @mousedown="selectOption(option.value)"
                    >
                        {{ option.label }}
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator';
import { VALIDATION_REMOVE_ERROR } from '@/constants';

@Component({
    props: {
        label: String,
        name: String,
        options: Array,
        placeholder: {
            default: 'Select an option',
            type: String,
        },
        scope: {
            default: 'default',
            type: String,
        },
        value: [String, Number],
    },
})
export default class InputSelect extends Vue {
    focused = false;

    get selectedLabel() {
        if (!this.value) return null;
        return this.options?.find((o) => o.value === this.value)?.label;
    }

    selectOption(value) {
        if (this.$store.getters.hasError(this.name, this.scope)) {
            this.$store.commit(VALIDATION_REMOVE_ERROR, {
                field: this.name,
                scope: this.scope,
            });
        }
        this.$emit('input', value);
    }
}
</script>
