<template>
    <Dialog :title="$t('professional.delete_event_modal.title')" @submit="onSubmit" @close="close">
        <div class="p-6">
            <div v-if="event && event.recurrence_rule" class="mb-4 flex items-center">
                <div class="mr-2 flex-none">
                    {{ $t('professional.recurrence_update_modes.when_confirming_delete') }}
                </div>
                <InputSelect
                    v-model="recurrenceUpdateMode"
                    name="update_mode"
                    :options="recurrenceUpdateModes"
                    class="z-50"
                />
            </div>

            <div class="mb-6">
                <p class="mb-2">{{ $t('professional.delete_event_modal.message') }}</p>
                <InputTextArea
                    v-model="reason"
                    name="cancelled_reason"
                    rows="2"
                    :placeholder="$t('professional.delete_event_modal.reason_placeholder')"
                    class="z-50"
                />
            </div>

            <div class="flex items-center justify-center">
                <button class="btn mx-3 justify-self-center p-3 text-center" @click.prevent="close">
                    {{ $t('professional.delete_event_modal.back') }}
                </button>
                <button class="btn mx-3 justify-self-center p-3 text-center">
                    {{ $t('professional.delete_event_modal.confirm') }}
                </button>
            </div>
        </div>
    </Dialog>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator';
import InputSelect from '@/components/input/InputSelect';
import InputTextArea from '@/components/input/InputTextArea';
import Dialog from '@/components/modals/Dialog';
import { REQUEST, VALIDATION_CREATE_MESSAGE } from '@/constants';

@Component({
    components: {
        InputSelect,
        InputTextArea,
        Dialog,
    },
})
export default class EventDeleteDialog extends Vue {
    reason = '';
    recurrenceUpdateMode = 'this';

    @Prop() event;
    @Prop() callback;

    close() {
        this.$root.$emit('modal-close');
    }

    async onSubmit() {
        if (this.loading) return;
        this.loading = true;
        const response = await this.$store.dispatch(REQUEST, {
            method: 'post',
            url: `events/${this.event.id}/cancel`,
            data: {
                cancelled_reason: this.reason,
                update_mode: this.recurrenceUpdateMode,
            },
        });

        this.loading = false;

        if (response.status === 200) {
            this.$store.dispatch(VALIDATION_CREATE_MESSAGE, {
                type: 'success',
                message: 'professional.delete_event_modal.event_cancelled',
            });
            this.callback();
            this.close();
        }
    }

    get recurrenceUpdateModes() {
        return [
            { value: 'this', label: this.$t('professional.recurrence_update_modes.this') },
            { value: 'future', label: this.$t('professional.recurrence_update_modes.future') },
            // { value: 'all', label: this.$t('professional.recurrence_update_modes.all') },
        ];
    }
}
</script>
